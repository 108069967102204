import { Taxon } from '../../typings/base'
import { ACTIONS } from '../actions/taxons'

interface ReducerState {
  loading: boolean
  loadingAttribute?: boolean
  loadingSizes?: boolean
  attributes?: any
  sizes?: any
  items: Taxon[]
  error?: any
}

export const initialProductsState: ReducerState = {
  loading: false,
  items: [],
  loadingAttribute: false,
  loadingSizes: false,
}

const taxonsReducer = (state = initialProductsState, action): ReducerState => {
  switch (action.type) {
    case ACTIONS.GET_TAXONS_REQUEST:
      return { ...state, loading: true }
    case ACTIONS.GET_TAXONS_ATTRIBUTES_REQUEST:
      return { ...state, loadingAttribute: true }
    case ACTIONS.GET_TAXONS_SIZES_REQUEST:
      return { ...state, loadingSizes: true }
    case ACTIONS.GET_TAXONS_SUCCESS:
      return { ...state, items: action.taxons, loading: false }
    case ACTIONS.GET_TAXONS_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loadingAttribute: false,
        attributes: action.attributes,
      }
    case ACTIONS.GET_TAXONS_SIZES_SUCCESS:
      return {
        ...state,
        loadingSizes: false,
        sizes: action.sizes,
      }
    case ACTIONS.GET_TAXONS_FAILURE:
      return { ...state, loading: false, error: action.error }
    case ACTIONS.GET_TAXONS_ATTRIBUTES_FAILURE:
      return { ...state, loadingAttribute: false, error: action.error }
    case ACTIONS.GET_TAXONS_SIZES_FAILURE:
      return { ...state, loadingSizes: false, error: action.error }
    default:
      return state
  }
}

export default taxonsReducer
