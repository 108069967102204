import { ORDER_ITEM_TYPE } from './constants'
import { ProductInCart, Taxon } from '@/typings/base'
import { Product as BaseProduct } from '@/typings/base'
import { OrderItem } from '@/interfaces/common/contract'
import { Image } from '@/typings/base'
import { StockReducerState } from '@/redux/reducers/stockReducer'
import { TakeBackReducerState } from '../redux/reducers/takeBackReducer'

export const TRANSITION_TIMEOUT = 250

export function truncateString(string, length, isHtmlDescription?) {
  if (!isHtmlDescription) {
    string = parseHTML(string)
  }
  if (string.length <= length) {
    return string
  }
  return string.slice(0, length) + '...'
}

export function parseHTML(HtmlString) {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = HtmlString
  tempDiv.remove()
  return tempDiv.innerText || ''
}

export const scrollToDiv = (nodeId) => {
  document.getElementById(`${nodeId}`)?.scrollIntoView({ behavior: 'smooth' })
}

export const setFullLocale = (locale: string) => {
  let fullLocale = ''
  process.env.PRISMIC_LOCALES.split(',').map((pLocale) => {
    if (locale === pLocale.substring(0, 2)) {
      fullLocale = pLocale
    }
  })
  return fullLocale
}

export const transformLocale = (locale: string, separator = '_') => {
  const [language, country] = locale.split('-')
  return `${language.toLowerCase()}${separator}${country.toUpperCase()}`
}

export const getSkus = (products) => {
  const SKUArray = []
  if (products?.items?.items?.length > 0) {
    products.items.items?.map((product) => {
      Object.keys(product.variants).map((variantKey) => {
        if (product.variants[variantKey].sku !== '') {
          SKUArray.push(product.variants[variantKey].sku)
        }
      })
    })
  } else if (typeof products?.name !== 'undefined') {
    Object.keys(products.variants).map((variantKey) => {
      if (products.variants[variantKey].sku !== '') {
        SKUArray.push(products.variants[variantKey].sku)
      }
    })
    if (products?.associations) {
      Object.keys(products?.associations).map((association) => {
        products.associations[association].map((pa) => {
          Object.keys(pa.variants).map((pav) => {
            SKUArray.push(pa.variants[pav].sku)
          })
        })
      })
    }
  } else if (products?.length > 0) {
    products?.map((product) => {
      if (product?.product?.variants) {
        Object.keys(product.product.variants).forEach((variantKey) => {
          if (product.product.variants[variantKey].sku !== '') {
            SKUArray.push(product.product.variants[variantKey].sku)
          }
        })
      } else if (product?.variants) {
        Object.keys(product.variants).forEach((variantKey) => {
          if (product.variants[variantKey].sku !== '') {
            SKUArray.push(product.variants[variantKey].sku)
          }
        })
      } else {
        product?.map((item) => {
          Object.keys(item.variants).forEach((variantKey) => {
            if (item.variants[variantKey].sku !== '') {
              SKUArray.push(item.variants[variantKey].sku)
            }
          })
        })
      }
    })
  }
  return SKUArray
}

export const checkProductAvailability = (stock: StockReducerState, product) => {
  let available = false
  let secondHandAvailable = false
  const secondHandImage: Image[] = []
  if (stock?.stock?.length > 0) {
    Object.keys(product?.variants).forEach((variant) => {
      const sku = stock.stock.find(
        (varSku) => varSku.lizeeSKU === product.variants[variant].sku
      )
      sku?.secondHand?.forEach((condition) => {
        if (typeof condition.quality === 'undefined') {
          return
        }
        if (condition?.quality === 'perfectCondition') {
          secondHandImage.push(...condition.images)
        } else {
          if (condition?.quality === 'veryGoodCondition') {
            secondHandImage.push(...condition.images)
          } else {
            if (condition?.quality === 'goodCondition') {
              secondHandImage.push(...condition.images)
            }
          }
        }
      })
      if (typeof sku !== 'undefined' && sku.quantity !== 0) {
        available = true
      }
      if (
        typeof sku !== 'undefined' &&
        sku.secondHand &&
        sku.secondHand.length > 0
      ) {
        secondHandAvailable = true
      }
    })
  }
  return {
    available: available,
    secondHandAvailable: secondHandAvailable,
    secondHandImage: secondHandImage,
  }
}

export const checkStockQuantity = (stock, variant, stockType?: string) => {
  if (stockType === 'basket') {
    if (stock?.stockBasket?.length > 0) {
      const exist = stock.stockBasket.find(
        (stock) => stock.lizeeSKU === variant?.sku
      )
      if (exist) {
        return exist.quantity
      } else {
        return 0
      }
    }
  } else {
    if (stock?.stock?.length > 0) {
      const exist = stock.stock.find((stock) => stock.lizeeSKU === variant?.sku)
      if (exist) {
        return exist.quantity
      } else {
        return 0
      }
    }
  }
}

export const arrayUnique = (array) => {
  const a = array.concat()
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1)
    }
  }

  return a
}

export const downloadInvoice = (url) => {
  const a = document.createElement('a')
  a.href = url
  a.setAttribute('download', 'file')
  a.setAttribute('title', 'Télecharger votre facture dans un nouvel onglet')
  a.setAttribute('target', '_blank')
  a.click()
}

export const downloadURI = (url, filename) => {
  fetch(url).then(function (t) {
    return t.blob().then((b) => {
      const a = document.createElement('a')
      a.href = URL.createObjectURL(b)
      a.setAttribute('download', filename)
      a.click()
    })
  })
}

// Because we get prices in cents from Sylius
export const numberToPrice = (amount: number, amountNotDivided?: boolean) => {
  let amountValue = ''
  amountNotDivided
    ? (amountValue = amount.toFixed(2).replace(/\.00$/, ''))
    : (amountValue = (amount / 100).toFixed(2).replace(/\.00$/, ''))
  return parseFloat(amountValue)
}

// Prismic Selects fields Options utils
export const columnGridLayout = (layout: string) => {
  let columnLeft
  let columnRight
  switch (layout) {
    case '1/2 - 1/2':
      columnLeft = { xs: 12, lg: 6 }
      columnRight = { xs: 12, lg: 6 }
      break
    case '1/3 - 2/3':
      columnLeft = { xs: 12, lg: 5 }
      columnRight = { xs: 12, lg: 7 }
      break
    default:
      columnLeft = { xs: 12, lg: 7 }
      columnRight = { xs: 12, lg: 5 }
      break
  }
  return { columnLeft, columnRight }
}

export const columnLayout = (columns: string) => {
  let gridItemWidth
  switch (columns) {
    case '3':
      gridItemWidth = 4
      break
    case '4':
      gridItemWidth = 3
      break
    case '6':
      gridItemWidth = 2
      break
    default:
      gridItemWidth = '20%'
      break
  }
  return gridItemWidth
}

export const columnSpacing = (spacing: string) => {
  let gridSpacing
  switch (spacing) {
    case 'Small':
      gridSpacing = 1
      break
    case 'Medium':
      gridSpacing = 3
      break
    case 'Large':
      gridSpacing = 6
      break
    default:
      gridSpacing = 0
      break
  }
  return gridSpacing
}

export const thumbnailRatio = (thumbRatio: string) => {
  let aspectRatio
  switch (thumbRatio) {
    case 'Portrait':
      aspectRatio = 'ar-3-4'
      break
    case 'Landscape 4:3':
      aspectRatio = 'ar-4-3'
      break
    case 'Landscape 16:9':
      aspectRatio = 'ar-16-9'
      break
    default:
      aspectRatio = 'ar-1-1'
      break
  }
  return aspectRatio
}

export const checkTryOutError = (cart) => {
  let globalQuantRented = 0
  let globalQuantTryOut = 0
  cart?.item?.items?.length > 0 &&
    cart?.item?.items?.map((item) => {
      if (!item?.withTryingOn) {
        globalQuantRented += item.quantity
      } else {
        globalQuantTryOut += item.quantity
      }
    })
  if (globalQuantRented >= globalQuantTryOut) {
    return false
  } else return true
}

export const calcPackMinPrice = (products) => {
  let minPrice = 0
  const priceArray = []
  //pack?.packProducts?.map((product) => {
  products?.map((product) => {
    const priceVariant = []
    const productQty = product?.packProductQuantity
      ? product?.packProductQuantity
      : 1
    product?.product?.variants.map((variant) => {
      // TODO need work here to not put channel in raw
      if (variant.channelPricings.FASHION_WEB) {
        priceVariant.push(
          variant.channelPricings.FASHION_WEB.price * productQty
        )
      } else if (variant.channelPricings.WEB) {
        priceVariant.push(variant.channelPricings.WEB.price * productQty)
      } else {
        priceVariant.push(variant.channelPricings.WEB1.price * productQty)
      }
    })
    priceArray.push(Math.min(...priceVariant))
  })
  minPrice = priceArray.reduce(function (a, b) {
    return a + b
  }, 0)
  return minPrice
}

export const apiHeaders = (connexionType: string, isV2?: boolean) => {
  if (connexionType === null) {
    if (isV2) {
      return {
        Authorization: `BEARER ${
          typeof window !== 'undefined' && localStorage?.getItem('TOKEN')
        }`,
        Accept: 'application/json',
      }
    } else {
      return {
        Authorization: `BEARER ${
          typeof window !== 'undefined' && localStorage?.getItem('TOKEN')
        }`,
      }
    }
  } else {
    if (isV2) {
      return {
        Authorization: `BEARER ${
          typeof window !== 'undefined' && localStorage?.getItem('TOKEN')
        }`,
        'Authorization-Type': connexionType,
        Accept: 'application/json',
      }
    } else {
      return {
        Authorization: `BEARER ${
          typeof window !== 'undefined' && localStorage?.getItem('TOKEN')
        }`,
        'Authorization-Type': connexionType,
        Accept: 'application/json',
      }
    }
  }
}

export const calculTotalWithBuyAbleProducts = (cart, isBuyAbleProducts) => {
  let total
  if (!cart?.item?.totals?.totalSecondHand) {
    total =
      cart.item.totals.totalItemWithoutPromotion + cart.item.totals.promotion
  } else {
    total =
      cart.item.totals.totalItemWithoutPromotion +
      cart.item.totals.promotion -
      cart?.item?.totals?.totalSecondHand
  }
  if (!isBuyAbleProducts) {
    cart?.item?.items?.map((product) => {
      if (product?.product?.variants[0].purchase) {
        total -= product?.product?.variants[0].purchasePrice * product.quantity
      }
    })
  } else {
    cart?.item?.items?.map((product) => {
      if (!product?.product?.variants[0].purchase) {
        total -= product?.total
      }
    })
  }
  return total
}

// Func Heading to handle font settings easily
export const heading = (
  fontSizeMobile: string,
  fontSizeDesktop: string,
  fontWeightMobile: any,
  fontWeightDesktop: any,
  fontFamilyMobile: string,
  fontFamilyDesktop: string,
  isImportant = false
) => ({
  fontWeight: isImportant ? `${fontWeightMobile} !important` : fontWeightMobile,
  fontFamily: isImportant ? `${fontFamilyMobile} !important` : fontFamilyMobile,
  fontSize: isImportant ? `${fontSizeMobile} !important` : fontSizeMobile,
  '& strong,& b': {
    fontWeight: isImportant
      ? `${fontWeightMobile} !important`
      : fontWeightMobile,
  },
  '@media (min-width: 1024px)': {
    fontWeight: isImportant
      ? `${fontWeightDesktop} !important`
      : fontWeightDesktop,
    fontFamily: isImportant
      ? `${fontFamilyDesktop} !important`
      : fontFamilyDesktop,
    fontSize: isImportant ? `${fontSizeDesktop} !important` : fontSizeDesktop,
    '& strong,& b': {
      fontWeight: isImportant
        ? `${fontWeightDesktop} !important`
        : fontWeightDesktop,
    },
  },
})

export const menuPosition = (position: string) => {
  let menuPosition
  switch (position) {
    case 'Left':
      menuPosition = 'Left'
      break
    case 'Right':
      menuPosition = 'Right'
      break
    case 'Center':
      menuPosition = 'Center'
      break
    default:
      menuPosition = 'No menu'
      break
  }
  return menuPosition
}

export const logoPosition = (position: string) => {
  let menuPosition
  switch (position) {
    case 'Center':
      menuPosition = 'Center'
      break
    case 'Right':
      menuPosition = 'Right'
      break
    default:
      menuPosition = 'Left'
      break
  }
  return menuPosition
}

export const findCurrentPlan = (promotions, qty: number, price: number) => {
  // Filter plans of coupon based promotions
  const plans = promotions?.items?.filter((item) => !item.coupon_based)

  plans?.map((plan) => {
    if (
      qty >= plan.rules[0].configuration.from &&
      qty <= plan.rules[0].configuration.to
    ) {
      price =
        plan.actions[0].configuration[
          Object.keys(promotions.items[0].actions[0].configuration)[0]
        ].amount
    }
  })
  return price
}

export const validXMLPath = (el) => {
  // We need to replace some characters in order to have XML valid file
  const cleanPath = el
    .replace(/ /g, '%20')
    .replace(/&/g, '&amp;')
    .replace(/'/g, '&apos;')
    .replace(/"/g, '&quot;')
    .replace(/>/g, '&gt;')
    .replace(/</g, '&lt;')
  return cleanPath
}

export const isPurchasedItem = (item: ProductInCart | OrderItem) => {
  return item?.type === ORDER_ITEM_TYPE.PURCHASE
}

export const btnOptions = (option: string) => {
  let btnOption
  switch (option) {
    case 'Above':
      btnOption = 'Above'
      break
    case 'Both':
      btnOption = 'Both'
      break
    default:
      btnOption = 'Below'
      break
  }
  return btnOption
}

export const findIfSlugExist = (taxons, slugs) => {
  const currentTaxon = taxons?.children?.find(
    (child) => child.slug === slugs[0]
  )
  if (currentTaxon === undefined) return false

  slugs.shift()

  if (slugs.length === 0) return true

  return findIfSlugExist(currentTaxon, slugs)
}

/**
 * recursively find the currentTaxon by slug === taxon.slug then replace the slug with the targetTaxon.slug in the url
 * @param url
 * @param slugs
 * @param currentTaxons
 * @param targetTaxons
 * @returns string
 */
export const translateUrlSlugs = (
  url: string,
  slugs: string[],
  currentTaxons: Taxon[],
  targetTaxons: Taxon[]
) => {
  if (
    !slugs ||
    slugs.length === 0 ||
    !currentTaxons ||
    !targetTaxons ||
    currentTaxons.length === 0 ||
    targetTaxons.length === 0
  )
    return url
  const currentSlug = slugs[0]
  const currentTaxon = currentTaxons.find(
    (taxon) => taxon?.slug === currentSlug
  )
  const targetTaxon = targetTaxons.find(
    (taxon) => taxon?.code === currentTaxon?.code
  )
  const newUrl = url.replace(currentSlug, targetTaxon?.slug)
  slugs.shift()
  if (slugs.length === 0) return newUrl
  return translateUrlSlugs(
    newUrl,
    slugs,
    currentTaxon.children,
    targetTaxon.children
  )
}

export const productLowestPrice = (
  product: BaseProduct,
  takeBackLP: TakeBackReducerState
) => {
  const takeBackMinPriceArray = []
  Object.values(product?.variants).forEach((variant) => {
    const currentVariant = takeBackLP?.takeBackStock?.find(
      (stock) => stock?.lizeeSKU === variant.sku
    )
    if (currentVariant?.productsByQuality.length > 0) {
      currentVariant?.productsByQuality.forEach((product) => {
        if (product.quality === 'goodCondition') {
          variant?.resalePrice &&
            takeBackMinPriceArray.push(variant?.resalePrice['goodCondition'])
        } else if (product.quality === 'veryGoodCondition') {
          variant?.resalePrice &&
            takeBackMinPriceArray.push(
              variant?.resalePrice['veryGoodCondition']
            )
        } else {
          variant?.resalePrice &&
            takeBackMinPriceArray.push(variant?.resalePrice['perfectCondition'])
        }
      })
    }
  })
  return takeBackMinPriceArray
}

export function secondHandCarrierIdByCountry(country?: string) {
  if (process.env.CARRIER_ID_EUROPE) {
    return country === 'FR'
      ? process.env.CARRIER_ID
      : process.env.CARRIER_ID_EUROPE
  } else {
    return process.env.CARRIER_ID
  }
}
